import styled from "styled-components";
import { COLORS } from "../globals/colors";
import { MOBILE_460, TABLET_992 } from "../globals/sizes";

export const Title = styled.h2`
  font-size: 36px;
  color: ${COLORS.redTitle};
  margin: auto;
  padding-top: 90px;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  @media (max-width: ${TABLET_992}px) {
    font-size: 30px;
    padding-top: 40px;
  }
  @media (max-width: ${MOBILE_460}px) {
    font-size: 26px;
    padding-top: 10px;
  }
`;

export const Subtitle = styled.h2`
  font-size: 26px;
  color: ${COLORS.grayText};
  margin: auto;
  padding-top: 12px;
  padding-bottom: 15px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: ${TABLET_992}px) {
    font-size: 20px;
  }
  @media (max-width: ${MOBILE_460}px) {
    font-size: 20px;
    padding-top: 10px;
    width: 90%;
  }
`;

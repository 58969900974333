import { Helmet, HelmetProvider } from "react-helmet-async";
import React, { useEffect } from "react";

import AOS from "aos";
import Layout from "../components/layout";

import HeroImage from "../images/aboutUs/life-about-us.webp";
import HeroTabletImage from "../images/aboutUs/life-about-us.webp";
import HeroMobileImage from "../images/aboutUs/life-about-us-mobile.webp";
import IconPhone from "../images/phone-call-white.svg";

import HeroBanner from "../components/HeroBanner";
import GetInTouchSection from "../components/GetInTouchSection";
import TeamCards from "../components/TeamCards";

import Dov from "../images/aboutUs/Dov.png";
import David from "../images/aboutUs/David.png";
import Isabella from "../images/aboutUs/Isabella.png";
import Kathy from "../images/aboutUs/Kathy.png";
import DrJoseph from "../images/aboutUs/DrJoseph.png";
import Richard from "../images/aboutUs/Richard.png";
import Marge from "../images/aboutUs/Marge.png";
import Andreza from "../images/aboutUs/Andreza.png";
import Christina from "../images/aboutUs/Christina.png";
import Nicole from "../images/aboutUs/Nicole.png";
import Reggie from "../images/aboutUs/Reggie.png";

import { Subtitle, Title } from "../styles/pages/about-us";

const AboutUs = () => {
  const team = [
    {
      name: "Dov Brafman, NREMT",
      position: "CEO",
      img: Dov,
    },
    {
      name: "David Teitelbaum",
      position: "CFO",
      img: David,
    },
    {
      name: "Isabella Ferreira, RN, BSN",
      position: "Administrator",
      img: Isabella,
    },
    {
      name: "Kathy Bassiacos, RN, BSN",
      position: "Director of Community Relations",
      img: Kathy,
    },
    {
      name: "Dr. Joseph Schulman, DO",
      position: "Hospice Medical Director",
      img: DrJoseph,
    },
    {
      name: "Richard Hogans, Rev., Chap.",
      position: "Spiritual Care Counselor and Bereavement Coordinator ",
      img: Richard,
    },
    {
      name: "Marge Armenta, LCSW",
      position: "Hospice Social Worker",
      img: Marge,
    },
    {
      name: "Andreza Catalunia, RN",
      position: "Registered Nurse / Case Manage",
      img: Andreza,
    },
    {
      name: "Christina Chapman, MHA",
      position: (
        <>
          Director of Human
          <br /> Resources and Operations
        </>
      ),
      img: Christina,
    },
    {
      name: "Reggie Bonnah, APN",
      position: "Nurse Practitioner",
      img: Reggie,
    },
    {
      name: "Nicole Beeney",
      position: "Volunteer Coordinator",
      img: Nicole,
    },
  ];
  useEffect(
    () => {
      AOS.init({
        duration: 2000,
        once: true,
      });
    },
    [],
    AOS
  );

  return (
    <HelmetProvider>
      <Layout>
        <Helmet>
          <link rel="canonical" href="https://hospicecare.life/" />
        </Helmet>
        <HeroBanner
          align={"center"}
          paragraphWidth="25%"
          paragraphFontSize="22px"
          HeroImage={HeroImage}
          HeroMobileImage={HeroMobileImage}
          HeroTabletImage={HeroTabletImage}
          title="MEET THE TEAM"
          paragraph="Meet some of our dedicated staff members!"
        />
        <Title>Learn more about the team!</Title>
        <Subtitle>
          Find out more about our team members by hovering over their pictures!
        </Subtitle>
        <TeamCards team={team} />
        <GetInTouchSection
          icon={IconPhone}
          title="Have more questions about hospice or want to learn more about the services life provides?"
          description="Call us at 973-607-4930. At Life Hospice, we’re dedicated to delivering compassionate care. Discover how we can help you and your loved ones today."
          mailto="isabella@hospicecare.life"
        />
      </Layout>
    </HelmetProvider>
  );
};

export default AboutUs;
